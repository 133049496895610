export const encodeRegexSpace = (input: string): string =>
  input?.replace(/\s/g, '\\s')

export const decodeRegexSpace = (input: string): string =>
  input.replace(/\\s/g, ' ')

const removeFileExtension = (input: string): string => {
  const lastDotIndex = input.lastIndexOf('.')
  if (lastDotIndex === -1) {
    return input
  }

  return input.slice(0, lastDotIndex)
}

export const getSanitizedFileName = (input: string): string => {
  const fileName = removeFileExtension(input)
  const sanitizedFileName = sanitizeString(fileName)

  return sanitizedFileName
}

export const sanitizeString = (string: string) => {
  const specialCharacters =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const normalizedCharacters =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const specialCharactersRegExp = new RegExp(
    specialCharacters.split('').join('|'),
    'g'
  )

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(specialCharactersRegExp, (c) =>
      normalizedCharacters.charAt(specialCharacters.indexOf(c))
    ) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const capitalize = (string: string): string => {
  if (typeof string !== 'string') {
    return ''
  }

  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
}

export const replaceAll = (
  input: string,
  search: string,
  replace: string
): string => input.split(search).join(replace)

export const isImageUrl = (url: string): boolean => {
  const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|svg|png|jpeg)/g

  return regex.test(url)
}

export const breakTextAndAddLimit = (
  text: string,
  breakEvery = 10,
  addEllipsisAt = 10
) => {
  const breakedText = text
    .split(' ')
    .reduce((acc: string, word: string) => {
      const lastLine = acc.split(' ').slice(-1)[0]
      if (lastLine && lastLine.length > breakEvery) {
        return `${acc} </br> ${word}`
      }
      return `${acc} ${word}`
    }, '')
    .trim()

  const lines = breakedText.split('</br>')
  if (lines.length <= addEllipsisAt) {
    return breakedText
  }

  const limitedText = lines.slice(0, addEllipsisAt).join('</br>')
  return `${limitedText}...`
}

export const orderAlphabetically = (
  a: string | undefined,
  b: string | undefined
): number => {
  const nameA = a?.toUpperCase()
  const nameB = b?.toUpperCase()

  if (nameA && nameB) {
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
  }
  return 0
}
