import React from 'react'
import {
  GridSortModel,
  GridPaginationModel,
  GridFilterModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro'
import useTableState from 'containers/Table/useTableState'
import getInitialState from 'containers/Table/utils/getInitialState'
import getFilter from 'containers/Table/utils/getFilter'
import GridToolbar from 'containers/Table/GridToolbar'
import GridFooter from 'containers/Table/GridFooter'
import ColumnMenu from 'containers/Table/ColumnMenu'
import ColumnsPanel from 'containers/Table/ColumnsPanel'
import FilterPanel from 'containers/Table/FilterPanel'
import customText from './customText'
import customIcons from './customIcons'
import { Sort, TableProperties } from 'lib/reports/types'
import * as S from './Table.styled'

const SERVER = 'server'

interface TableProps {
  report: Report
  reportProperties: TableProperties
  rows: number
}

const Table = (props: TableProps) => {
  const { report, reportProperties, rows: reportRows } = props
  const { id: reportId } = report
  const {
    columnsButton,
    filtersButton,
    exportButton,
    search,
    footer = true,
    info,
    rows: { mapping: rowsMapping },
  } = reportProperties
  const toolbar = Boolean(
    columnsButton || filtersButton || exportButton || search
  )
  const [reportDataSet] = report.reportDataSets?.items || []
  const {
    dataSet: { id: dataSetId },
  } = reportDataSet

  const { tableState, setTableState } = useTableState({
    tableProperties: reportProperties,
    dataSetId,
  })

  const {
    columns,
    rowIdName,
    rows,
    total,
    paginationModel,
    pageSizeOptions,
    loading,
  } = tableState

  const initialState = getInitialState({ tableProperties: reportProperties })

  const onSortModelChange = (sortModel: GridSortModel) => {
    const [{ field: defaultField, sort: direction }] = sortModel
    const field = rowsMapping[defaultField]
    const sort = { field, direction } as Sort

    setTableState((tableState) => ({ ...tableState, sort }))
  }

  const onPaginationModelChange = (paginationModel: GridPaginationModel) => {
    setTableState((tableState) => ({
      ...tableState,
      paginationModel,
    }))
  }

  const onFilterModelChange = (filterModel: GridFilterModel) => {
    const filter = getFilter({ tableProperties: reportProperties, filterModel })

    setTableState((tableState) => ({
      ...tableState,
      filter,
    }))
  }

  return (
    <S.TableContainer rows={reportRows}>
      <S.DataGrid
        columns={columns}
        getRowId={(row: GridValidRowModel) => row[rowIdName]}
        rows={rows}
        initialState={initialState}
        sortingMode={SERVER}
        onSortModelChange={onSortModelChange}
        pagination={true}
        pageSizeOptions={pageSizeOptions}
        paginationMode={SERVER}
        rowCount={total}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        filterMode={SERVER}
        onFilterModelChange={onFilterModelChange}
        toolbar={toolbar}
        footer={footer}
        localeText={customText}
        slots={{
          ...customIcons,
          //@ts-ignore
          toolbar: () => (
            <GridToolbar
              toolbar={toolbar}
              dataSetId={dataSetId}
              rowsMapping={rowsMapping}
              reportId={reportId}
              tableProperties={reportProperties}
              tableState={tableState}
              setTableState={setTableState}
            />
          ),
          columnMenu: ColumnMenu,
          columnsPanel: ColumnsPanel,
          filterPanel: FilterPanel,
          footer: () => (
            <GridFooter footer={footer} info={info} loading={loading} />
          ),
        }}
        loading={loading}
      />
    </S.TableContainer>
  )
}

export default Table
