import eventFragment from 'gql/fragments/event'

const onEventCreated = /* GraphQL */ `
  ${eventFragment}
  subscription onEventCreated {
    onEventCreated {
      ...eventFields
    }
  }
`

export default onEventCreated
