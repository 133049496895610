import { lazy } from 'react'
import { reloadPageOnChunkError } from 'lib/lazy'
import { USER_TYPE } from 'lib/userType'
import { BasicRoute } from '../types'

interface GetAdminRoutesParams {
  account: Account
}

export const USERS: BasicRoute = {
  routeName: 'Users',
  component: lazy(() => import('pages/Users').catch(reloadPageOnChunkError)),
  exact: true,
}

const getAdminRoutes = (params: GetAdminRoutesParams) => {
  const { account } = params
  const { userType } = account
  const { ADMIN } = USER_TYPE
  const renderConditional = userType === ADMIN

  const USERS_ROUTE: BasicRoute = {
    ...USERS,
    renderConditional,
  }

  return [USERS_ROUTE]
}

export default getAdminRoutes
