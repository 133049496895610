import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLOperationParams, GraphQLOperationTypeEnum } from '../types'

const { QUERY, MUTATION, SUBSCRIPTION } = GraphQLOperationTypeEnum

const executeAppSyncOperation = (params: GraphQLOperationParams) => {
  const { operationType, operation, options = {} } = params
  const { variables = {}, onData } = options

  switch (operationType) {
    case QUERY:
    case MUTATION:
      return API.graphql(graphqlOperation(operation, variables))

    case SUBSCRIPTION:
      // @ts-ignore
      return API.graphql(graphqlOperation(operation, variables)).subscribe({
        next: onData,
      })

    default:
      throw new Error(
        `Unsupported operation type for AppSync: ${operationType}`
      )
  }
}

export default executeAppSyncOperation
