import { styled } from '@mui/material/styles'
import {
  Chip as MaterialChip,
  ChipProps as MaterialChipProps,
} from '@mui/material'

export const TableCellIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
})

export const TableCellLink = styled('a')({
  color: 'var(--color-primary)',
})

interface ChipProps extends MaterialChipProps {
  backgroundColor?: string
  textColor?: string
}

export const TableCellChip = styled(MaterialChip)<ChipProps>(
  ({
    backgroundColor = 'var(--color-grey-200)',
    textColor = 'var(--color-black)',
    variant,
  }) => ({
    backgroundColor,
    color: textColor,
    ...(variant === 'outlined' && {
      backgroundColor: 'transparent',
      border: `2px solid ${backgroundColor}`,
    }),
  })
)
