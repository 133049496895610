import React, {
  Dispatch,
  SetStateAction,
  createContext,
  ReactNode,
  useState,
  useEffect,
  useContext,
} from 'react'
import { useSnackbar } from 'notistack'
import { useReports } from 'contexts/reports'
import useGraphQLOperation from 'hooks/useGraphQLOperation'
import getReportDataSetsByDataSetIds from './getReportDataSetsByDataSetIds'
import getReportDataSetsListData from './getReportDataSetsListData'
import { formatMessage } from 'i18n/ShimokuIntl'
import { REPORT_TYPES } from 'lib/reports'
import { ReportDataSetsByDataSetIds } from './types'
import { useDashboardParams } from 'hooks/useDashboardParams'

import getDefaultReportDataSets from './getDefaultReportDataSets'

const { FILTERDATASET } = REPORT_TYPES

interface ReportDataSetsContextValue {
  reportDataSets: ReportDataSet[]
  reportDataSetsByDataSetIds: ReportDataSetsByDataSetIds
  setReportDataSets: Dispatch<SetStateAction<ReportDataSet[]>>
}

interface ReportDataSetsProviderProps {
  children: ReactNode
}

const ReportDataSetsContext = createContext<
  ReportDataSetsContextValue | undefined
>(undefined)

const getFilteredReports = (reports: Report[]) =>
  reports.filter(({ reportType }) => reportType === FILTERDATASET)

const ReportDataSetsProvider = ({ children }: ReportDataSetsProviderProps) => {
  const { reports } = useReports()
  const { enqueueSnackbar } = useSnackbar()
  const graphQLOperation = useGraphQLOperation()
  const { token, dashboardId, shared } = useDashboardParams()

  const filterDataSetReports = getFilteredReports(reports)
  const defaultReportDataSets = getDefaultReportDataSets(
    reports,
    filterDataSetReports
  )

  const [reportDataSets, setReportDataSets] = useState<ReportDataSet[]>(
    defaultReportDataSets
  )
  const reportDataSetsByDataSetIds = getReportDataSetsByDataSetIds({
    reportDataSets,
  })

  const fetchDataSetsListData = async () => {
    const { rejectedReportDataSets, fulfilledReportDataSets } =
      await getReportDataSetsListData({
        graphQLOperation,
        reportDataSets: defaultReportDataSets,
        publicPermissions: { token, dashboardId },
        shared,
      })

    rejectedReportDataSets.forEach(({ dataSet }) => {
      const errorMessage = dataSet.errorMessage as string
      enqueueSnackbar(formatMessage('errors.listData', { errorMessage }), {
        variant: 'error',
      })
    })
    setReportDataSets(fulfilledReportDataSets)
  }

  useEffect(() => {
    fetchDataSetsListData()
  }, []) // eslint-disable-line

  return (
    <ReportDataSetsContext.Provider
      value={{ reportDataSets, reportDataSetsByDataSetIds, setReportDataSets }}
    >
      {children}
    </ReportDataSetsContext.Provider>
  )
}

const useReportDataSets = () => {
  const context = useContext(ReportDataSetsContext)

  if (!context) {
    throw new Error(
      'useReportDataSets must be used within a ReportDataSetsProvider'
    )
  }

  return context
}

export { ReportDataSetsProvider, useReportDataSets }
