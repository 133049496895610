import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useReducer,
  useEffect,
  useCallback,
} from 'react'
import { OnDataOptions } from '@apollo/client'
import useGraphQLOperation from 'hooks/useGraphQLOperation'
import { GraphQLOperationTypeEnum } from 'hooks/useGraphQLOperation/types'
import usePlaygroundIsRunning from 'hooks/usePlaygroundIsRunning'
import realTimeEventReducer from './realTimeEventsReducer'
import onEventCreatedSubscription from 'gql/subscriptions/onEventCreated'
import {
  RealTimeEvent,
  RegisterRealTimeComponentParams,
  UnregisterRealTimeComponentParams,
} from './types'
import { RealTimeEventsReducerActionType } from './realTimeEventsReducer/types'

const { ADD_REAL_TIME_EVENT } = RealTimeEventsReducerActionType
const { SUBSCRIPTION } = GraphQLOperationTypeEnum

interface RealTimeContextValue {
  registerRealTimeComponent: (params: RegisterRealTimeComponentParams) => void
  unregisterRealTimeComponent: (
    params: UnregisterRealTimeComponentParams
  ) => void
}

const RealTimeContext = createContext<RealTimeContextValue | undefined>(
  undefined
)

interface RealTimeProviderProps {
  children: ReactNode
}

const RealTimeProvider = (props: RealTimeProviderProps) => {
  const { children } = props
  const graphQLOperation = useGraphQLOperation()
  const playgroundIsRunning = usePlaygroundIsRunning()
  const realTimeEventInitialState = {} as RealTimeEvent
  const [realTimeEvent, dispatchRealTimeEvent] = useReducer(
    realTimeEventReducer,
    realTimeEventInitialState
  )
  const [realTimeComponents, setRealTimeComponents] = useState(new Map())

  const registerRealTimeComponent = useCallback(
    (params: RegisterRealTimeComponentParams) => {
      const { id, callback } = params

      setRealTimeComponents((prevRealTimeComponents) => {
        const newRealTimeComponents = new Map(prevRealTimeComponents)
        newRealTimeComponents.set(id, callback)

        return newRealTimeComponents
      })
    },
    []
  ) // eslint-disable-line

  const unregisterRealTimeComponent = useCallback(
    (params: UnregisterRealTimeComponentParams) => {
      const { id } = params
      setRealTimeComponents((prevRealTimeComponents) => {
        const newRealTimeComponents = new Map(prevRealTimeComponents)
        newRealTimeComponents.delete(id)

        return newRealTimeComponents
      })
    },
    []
  ) // eslint-disable-line

  const value = { registerRealTimeComponent, unregisterRealTimeComponent }

  useEffect(() => {
    realTimeComponents.forEach((callback) => {
      callback()
    })
  }, [realTimeEvent]) // eslint-disable-line

  useEffect(() => {
    if (Boolean(playgroundIsRunning)) {
      const onData = (options: OnDataOptions) => {
        dispatchRealTimeEvent({
          type: ADD_REAL_TIME_EVENT,
          //@ts-ignore
          payload: options.data.onEventCreated,
        })
      }

      const options = { onData }
      graphQLOperation({
        operationType: SUBSCRIPTION,
        operation: onEventCreatedSubscription,
        options,
      })
    }
  }, []) // eslint-disable-line

  return (
    <RealTimeContext.Provider value={value}>
      {children}
    </RealTimeContext.Provider>
  )
}

const useRealTime = () => {
  const context = useContext(RealTimeContext)

  if (context === undefined) {
    throw new Error('useRealTime must be used within a RealTimeProvider')
  }

  return context
}

export { RealTimeProvider, useRealTime }
