import { RealTimeEvent } from '../types'

export enum RealTimeEventsReducerActionType {
  ADD_REAL_TIME_EVENT = 'ADD_REAL_TIME_EVENT',
}

export interface RealTimeEventsReducerAction {
  type: RealTimeEventsReducerActionType
  payload: RealTimeEvent
}
