import React, { Dispatch, SetStateAction } from 'react'
import { GridToolbarQuickFilter } from '@mui/x-data-grid-pro'
import ExportTableDataToCSV from 'containers/Table/ExportTableDataToCSV'
import { TableState } from 'containers/Table/types'
import ColumnsA from 'icons/Line/columns-a'
import FilterA from 'icons/Line/filter-a'
import { MappingObject, TableProperties } from 'lib/reports/types'
import * as S from './GridToolbar.styled'

interface GridToolbarProps {
  toolbar: boolean
  dataSetId: string
  rowsMapping: MappingObject
  reportId: string
  tableProperties: TableProperties
  tableState: TableState
  setTableState: Dispatch<SetStateAction<TableState>>
}

const GridToolbar = (props: GridToolbarProps) => {
  const {
    toolbar,
    dataSetId,
    rowsMapping,
    reportId,
    tableProperties,
    tableState,
    setTableState,
  } = props
  const {
    columnsButton = false,
    filtersButton = false,
    exportButton = false,
    search = false,
  } = tableProperties

  if (!toolbar) {
    return null
  }

  return (
    <S.GridToolbarContainer>
      <S.GridToolbarButtons>
        {Boolean(columnsButton) && (
          <S.ColumnsButton
            data-testid="ColumnsButton"
            startIcon={
              <S.IconContainer>
                <ColumnsA width={20} height={20} />
              </S.IconContainer>
            }
            disableRipple
          />
        )}
        {Boolean(filtersButton) && (
          <S.FilterButton
            data-testid="FilterButton"
            componentsProps={{
              button: {
                startIcon: (
                  <S.IconContainer>
                    <FilterA width={20} height={20} />
                  </S.IconContainer>
                ),
                disableRipple: true,
              },
            }}
          />
        )}
        {Boolean(exportButton) && (
          <ExportTableDataToCSV
            dataSetId={dataSetId}
            rowsMapping={rowsMapping}
            reportId={reportId}
            tableState={tableState}
            setTableState={setTableState}
          />
        )}
      </S.GridToolbarButtons>
      {Boolean(search) && <GridToolbarQuickFilter data-testid="QuickFilter" />}
    </S.GridToolbarContainer>
  )
}

export default GridToolbar
