import React, { useState, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'
import AccountsMenu from 'containers/AccountsMenu'
import { KindsOfUser } from 'containers/OnBoarding/types'
import MenuItem from 'components/UserMenu/MenuItem'
import { formatMessage } from 'i18n/ShimokuIntl'
import { USER_TYPE } from 'lib/userType'
import Settings from 'icons/Line/settings'
import UserMultiple from 'icons/Line/user-multiple'
import Accounts from 'icons/Line/accounts-a'
import SignOut from 'icons/Line/sign-out-a'
import SuscriptionsIcon from 'icons/Line/subscriptions-a'
import { Bright } from 'icons/Chunk/bright/bright'
import UserIcon from 'icons/Line/user'
import IASolutionsIcon from 'icons/Line/suite-anomaly-b'
import { generateRoutes } from 'components/Routes/generateRoutes'
import { USERS } from 'components/Routes/routes/getAdminRoutes'
import {
  SETTINGS,
  IA_SOLUTIONS,
  SUBSCRIPTIONS,
} from 'components/Routes/routes/getAuthenticatedRoutes'
import { UniverseModules } from 'redux/universeModules/types'
import * as S from './UserMenu.styled'
import { usePostHog } from 'posthog-js/react'
import { SUSCRIPTIONS_EVENTS, USER_EVENTS } from 'lib/postHogEvents'

const { Founders, MarketingAndSales, PythonDev } = KindsOfUser

export const USER_MENUS = {
  MAIN: 'main',
  ACCOUNTS: 'accounts',
}

const [
  { path: settingsPath },
  { path: usersPath },
  { path: iaSolutionsPath },
  { path: subscriptionsPath },
] = generateRoutes({
  routes: [SETTINGS, USERS, IA_SOLUTIONS, SUBSCRIPTIONS],
})
interface userMenuProps {
  user: User
  account: Account
  universeModules: UniverseModules
  signOut: (global: any) => Promise<void>
  hasEditPermission?: boolean
  handleUpgradePlan?: () => void
}

const UserMenu = (props: userMenuProps) => {
  const {
    user,
    account,
    universeModules,
    signOut,
    hasEditPermission = false,
    handleUpgradePlan,
  } = props
  const { kindOfUser } = user
  const { userType } = account
  const { MAIN, ACCOUNTS } = USER_MENUS
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setActiveMenu(MAIN)
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const [activeMenu, setActiveMenu] = useState(MAIN)
  const posthog = usePostHog()

  const { ADMIN, USER } = USER_TYPE

  const isUsersMenuVisible =
    (userType === USER && hasEditPermission) || userType === ADMIN
  const isIaSoltionsMenuVisible =
    kindOfUser === Founders || kindOfUser === MarketingAndSales
  const { SUBSCRIPTION_PLANS } = universeModules
  const isSubscriptionsMenuVisible =
    Boolean(SUBSCRIPTION_PLANS) &&
    userType === ADMIN &&
    kindOfUser === PythonDev
  const accounts = user?.accounts?.items || []

  return (
    <>
      <S.MenuButton onClick={handleClick} data-testId="user-menu-button">
        <S.ContainerUserIcon
          className="ph-no-capture"
          onClick={() => posthog?.capture(USER_EVENTS.OPEN_MENU)}
        >
          <UserIcon color="var(--color-white)" />
        </S.ContainerUserIcon>
      </S.MenuButton>
      <S.Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {activeMenu === MAIN && (
          <>
            <Link to={settingsPath} data-testid="settings-menu-item">
              <MenuItem
                onClick={handleClose}
                position="first"
                leftIcon={
                  <Settings width="35px" color="var(--color-primary)" />
                }
              >
                {formatMessage('generic.settings')}
              </MenuItem>
            </Link>

            {accounts.length > 1 && (
              <MenuItem
                testId="accounts-menu-item"
                onClick={() => setActiveMenu(ACCOUNTS)}
                leftIcon={
                  <Accounts width="35px" color="var(--color-primary)" />
                }
              >
                {formatMessage('generic.workspaces')}
              </MenuItem>
            )}

            {isUsersMenuVisible && (
              <Link to={usersPath} data-testid="invite-users-menu-item">
                <MenuItem
                  onClick={handleClose}
                  leftIcon={
                    <UserMultiple width="35px" color="var(--color-primary)" />
                  }
                >
                  {formatMessage('generic.users')}
                </MenuItem>
              </Link>
            )}

            {isIaSoltionsMenuVisible && (
              <Link to={iaSolutionsPath} data-testid="ia-solutions-menu-item">
                <MenuItem
                  onClick={handleClose}
                  leftIcon={
                    <IASolutionsIcon
                      width="35px"
                      color="var(--color-primary)"
                    />
                  }
                >
                  {formatMessage('generic.iaSolutions')}
                </MenuItem>
              </Link>
            )}

            {isSubscriptionsMenuVisible && (
              <>
                <Link
                  to={subscriptionsPath}
                  onClick={() => posthog?.capture(SUSCRIPTIONS_EVENTS.OPENED)}
                >
                  <MenuItem
                    onClick={handleClose}
                    testId="subscriptions-menu-item"
                    leftIcon={<SuscriptionsIcon color="var(--color-primary)" />}
                  >
                    {formatMessage('generic.subscriptions')}
                  </MenuItem>
                </Link>

                <MenuItem
                  leftIcon={<Bright color="var(--color-white)" />}
                  highlighted={true}
                  onClick={() => {
                    handleClose()
                    handleUpgradePlan?.()
                  }}
                >
                  {formatMessage('generic.upgradePlan')}
                </MenuItem>
              </>
            )}

            <MenuItem
              onClick={signOut as any}
              position="last"
              leftIcon={
                <SignOut
                  width="35px"
                  color="var(--complementary-red)"
                  className="ph-no-capture"
                />
              }
            >
              <Typography
                component="span"
                variant="bodyS400"
                color="var(--complementary-red)"
              >
                {formatMessage('generic.signOut')}
              </Typography>
            </MenuItem>
          </>
        )}

        {activeMenu === ACCOUNTS && accounts && (
          <AccountsMenu setActiveMenu={setActiveMenu} />
        )}
      </S.Menu>
    </>
  )
}

export default UserMenu
