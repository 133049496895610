import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useApollo } from 'contexts/apollo'
import usePlaygroundIsRunning from 'hooks/usePlaygroundIsRunning'
import executeAppSyncOperation from './executeAppSyncOperation'
import executeApolloOperation from './executeApolloOperation'
import { GraphQLClient, GraphQLOperationParams } from './types'

const { APP_SYNC, APOLLO } = GraphQLClient

const useGraphQLOperation = () => {
  const playgroundIsRunning = usePlaygroundIsRunning()
  const { apolloClient } = useApollo()

  const graphQLClient = playgroundIsRunning ? APOLLO : APP_SYNC

  const graphQLOperation = async (params: GraphQLOperationParams) => {
    const { operationType, operation, options, fetchPolicy } = params

    switch (graphQLClient) {
      case APP_SYNC:
        return executeAppSyncOperation({
          operationType,
          operation,
          options,
        })

      case APOLLO:
        return executeApolloOperation({
          operationType,
          operation,
          options,
          fetchPolicy,
          apolloClient: apolloClient as ApolloClient<NormalizedCacheObject>,
        })

      default:
        throw new Error(`Unsupported graphQL client: ${graphQLClient}`)
    }
  }

  return graphQLOperation
}

export default useGraphQLOperation
