import React, { useRef, useEffect, CSSProperties } from 'react'
import { init, getInstanceByDom, EChartsOption, ECharts } from 'echarts'
import { useLayoutState } from 'hooks/components'

interface ReactEChartsProps {
  option: EChartsOption
  style?: CSSProperties
  theme?: string
  handleOnReady?: (echartRef: HTMLDivElement | null) => void
  handleOnClickDataPoint?: (params: any) => void
}

const ReactEcharts = (props: ReactEChartsProps) => {
  const { option, style, theme, handleOnReady, handleOnClickDataPoint } = props
  const { matches: isDesktop } = useLayoutState()

  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let chart: ECharts | undefined

    if (chartRef.current !== null) {
      chart = init(chartRef.current, theme, {
        renderer: isDesktop ? 'canvas' : 'svg',
      })
    }

    const resizeChart = () => {
      chart?.resize()
    }

    const resizeObserver = new ResizeObserver(() => {
      resizeChart()
    })

    chart?.on('finished', () => {
      if (chartRef.current) {
        resizeObserver.observe(chartRef.current)
      }
    })

    window.addEventListener('resize', resizeChart)

    return () => {
      chart?.dispose()
      resizeObserver.disconnect()
      window.removeEventListener('resize', resizeChart)
    }
  }, [theme]) // eslint-disable-line

  useEffect(() => {
    if (handleOnReady) {
      handleOnReady(chartRef.current)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current)
      chart.on('click', (params) => {
        handleOnClickDataPoint?.(params)
      })
      chart.setOption(option)
    }
  }, []) // eslint-disable-line

  return (
    <div
      ref={chartRef}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        ...style,
      }}
    />
  )
}

export default ReactEcharts
