import { GraphQLOperationParams } from 'hooks/useGraphQLOperation/types'
import getReportDataSetsQueries from './getReportDataSetsQueries'
import setReportDataSetsTotal from './setReportDataSetsTotal'
import setReportDataSetsListData from './setReportDataSetsListData'
import generateReportDataSetsWithAggregationProperty from '../aggregation/generateReportDataSetsWithAggregationProperty'
import generateAggregations from '../aggregation/generateReportDataSetsAggregation'
import { PROMISE_STATUS } from 'lib/promises'
import listDataTotal from 'gql/queries/listDataTotal'
import listData from 'gql/queries/listData'

const { FULFILLED, REJECTED } = PROMISE_STATUS
export const LIMIT = 100

export type PublicPermissions = {
  token: string
  dashboardId: string
}

interface GetReportDataSetsListDataParams {
  graphQLOperation: (params: GraphQLOperationParams) => Promise<any>
  reportDataSets: ReportDataSet[]
  limit?: number
  publicPermissions?: PublicPermissions
  shared?: boolean
}

const getReportDataSetsListData = async (
  params: GetReportDataSetsListDataParams
) => {
  const {
    graphQLOperation,
    reportDataSets,
    limit = LIMIT,
    publicPermissions,
    shared = false,
  } = params

  const reportDataSetsTotal = await Promise.allSettled(
    getReportDataSetsQueries({
      graphQLOperation,
      reportDataSets,
      query: listDataTotal,
      limit,
      publicPermissions,
      shared,
    })
  ).then((res) => setReportDataSetsTotal({ reportDataSets, res, limit }))

  const reportDataSetsListData = await Promise.allSettled(
    getReportDataSetsQueries({
      graphQLOperation,
      reportDataSets: reportDataSetsTotal,
      query: listData,
      limit,
      publicPermissions,
      shared,
    })
  ).then((res) =>
    setReportDataSetsListData({ reportDataSets: reportDataSetsTotal, res })
  )

  const rejectedReportDataSets = reportDataSetsListData.filter(
    ({ dataSet: { listDataStatus } }) => listDataStatus === REJECTED
  )

  const fulfilledReportDataSets = reportDataSetsListData.filter(
    ({ dataSet: { listDataStatus } }) => listDataStatus === FULFILLED
  )

  const reportDataSetsWithAggregationProperty =
    generateReportDataSetsWithAggregationProperty({
      reportDataSets: fulfilledReportDataSets,
    })

  const reportDataSetsWithAggregations = generateAggregations({
    reportDataSets: reportDataSetsWithAggregationProperty,
  })

  return {
    rejectedReportDataSets,
    fulfilledReportDataSets: reportDataSetsWithAggregations,
  }
}

export default getReportDataSetsListData
