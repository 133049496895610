import React from 'react'
import { useReports } from 'contexts/reports'
import ReportsComponent from 'components/Reports'
import { AppRoute } from 'components/Routes/types'

interface ReportProps {
  app: App
  appRoute: AppRoute
  padding?: boolean
}

const Reports = (props: ReportProps) => {
  const { app } = props
  const { reports } = useReports()

  return <ReportsComponent app={app} reports={reports} />
}

export default Reports
