import React, { Dispatch, SetStateAction } from 'react'
import { Tooltip } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { selectPlan } from 'redux/plan'
import ConditionalWrapper from 'hocs/conditionalWrapper'
import useGraphQLOperation from 'hooks/useGraphQLOperation'
import getAllTableData from 'containers/Table/utils/getAllTableData'
import { TableState } from 'containers/Table/types'
import generateTableRows from 'containers/Table/utils/generateTableRows'
import Download from 'icons/Line/download'
import { downloadCSV } from 'lib/files'
import { formatMessage } from 'i18n/ShimokuIntl'
import { MappingObject } from 'lib/reports/types'
import { IconContainer } from '../GridToolbar/GridToolbar.styled'
import * as S from '../GridToolbar/GridToolbar.styled'

interface ExportTableDataToCSVProps {
  dataSetId: string
  rowsMapping: MappingObject
  reportId: string
  tableState: TableState
  setTableState: Dispatch<SetStateAction<TableState>>
}

const ExportTableDataToCSV = (props: ExportTableDataToCSVProps) => {
  const { dataSetId, rowsMapping, reportId, tableState, setTableState } = props
  const { enqueueSnackbar } = useSnackbar()
  const apiRef = useGridApiContext()
  const {
    limits: { enabledReportDataDownload = false },
  } = useSelector(selectPlan)
  const graphQLOperation = useGraphQLOperation()

  const handleOnClick = async () => {
    setTableState((prevTableState) => ({ ...prevTableState, loading: true }))
    const dataItems = await getAllTableData({
      dataSetId,
      tableState,
      graphQLOperation,
      enqueueSnackbar,
    })
    const visibleColumns = apiRef.current.getVisibleColumns()
    const headers = visibleColumns.map(({ headerName }) => headerName as string)
    const mapping = visibleColumns.reduce(
      (mapping, { field, headerName }) => ({
        ...mapping,
        [headerName as string]: rowsMapping[field],
      }),
      {} as MappingObject
    )
    const rows = generateTableRows({
      dataItems,
      rowsMapping: mapping,
      CSV: true,
    })
    const title = `Table-${reportId}-${new Date()}`
    downloadCSV({ headers, rows, title })
    setTableState((prevTableState) => ({ ...prevTableState, loading: false }))
  }

  return (
    <ConditionalWrapper
      condition={!enabledReportDataDownload}
      wrapper={(children) => (
        <Tooltip title={formatMessage('info.featureNotAvaibleInPlan')} arrow>
          <span>{children}</span>
        </Tooltip>
      )}
    >
      <S.ButtonExportTableDataToCSV
        data-testid="ExportTableDataToCSV"
        variant="text"
        disableRipple
        startIcon={
          <IconContainer>
            <Download
              width={20}
              height={20}
              color={
                enabledReportDataDownload
                  ? 'var(--color-black)'
                  : 'var(--action-disabled)'
              }
            />
          </IconContainer>
        }
        disabled={!enabledReportDataDownload}
        onClick={handleOnClick}
      >
        <span className="toolbar-button-text">
          {formatMessage('generic.export')}
        </span>
      </S.ButtonExportTableDataToCSV>
    </ConditionalWrapper>
  )
}

export default ExportTableDataToCSV
