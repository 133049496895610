import { RealTimeEvent } from '../types'
import {
  RealTimeEventsReducerAction,
  RealTimeEventsReducerActionType,
} from './types'

const { ADD_REAL_TIME_EVENT } = RealTimeEventsReducerActionType

const realTimeEventsReducer = (
  state: RealTimeEvent,
  action: RealTimeEventsReducerAction
) => {
  switch (action.type) {
    case ADD_REAL_TIME_EVENT:
      return action.payload
    default:
      return state
  }
}

export default realTimeEventsReducer
