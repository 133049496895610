import React from 'react'
import { useDashboardParams } from 'hooks/useDashboardParams'
import SharedRoutes from 'containers/SharedRoutes'
import Routes from 'components/Routes'

const AppRoutes = () => {
  const { shared } = useDashboardParams()
  return shared ? <SharedRoutes /> : <Routes />
}

export default AppRoutes
