import React, { useState, useEffect, useMemo, useRef } from 'react'
import { VariableSizeList as VirtualizedList } from 'react-window'
import { sanitizeString } from 'lib/strings'
import SelectInput from '../select-input'
import SearchInput from '../SearchInput'
import SelectOption from './SelectOption'
import * as S from './Select.styled'

export type HandleInputValues = {
  value: string | null
  eventName: string
}

type SelectProps = {
  options: string[]
  fullWidth?: boolean
  labelId: string
  label: string
  id: string
  defaultValue: string
  value: string[]
  name: string
  maxOptions?: number
  isMultiple?: boolean
  showSearch?: boolean
  disabled?: boolean
  minWidth?: string
  textAlign?: 'left' | 'center' | 'right'
  hideArrow?: boolean
  handleInputChange: ({ value, eventName }: HandleInputValues) => void
}

const Select = (props: SelectProps) => {
  const {
    options,
    fullWidth,
    labelId,
    label,
    id,
    defaultValue,
    name,
    handleInputChange,
    maxOptions = 5,
    isMultiple = false,
    showSearch = false,
    disabled = false,
    minWidth,
    textAlign,
    hideArrow = false,
  } = props
  const arrayValue = useMemo(() => {
    return defaultValue ? defaultValue.split(',') : []
  }, [defaultValue])
  const [selected, setSelected] = useState<string | string[]>(
    isMultiple ? [] : ''
  )
  const [filteredOptions, setFilteredOptions] = useState<string[]>(options)
  const [open, setOpen] = useState<boolean>(false)
  const virtualizedListRef = useRef<any | null>(null)
  const selectOptionsHeight = useRef<{ [index: string]: number }>({})

  const handleOpenSelect = () => {
    setOpen(true)
    focusOnSearchInput()
  }

  const handleCloseSelect = () => {
    setOpen(false)
    setFilteredOptions(options)
  }

  const handleSearch = (value: string) => {
    const searchValue = sanitizeString(value)
    const filteredOptions = options.filter((option) => {
      const sanitizedOption = sanitizeString(option)
      return sanitizedOption.includes(searchValue)
    })
    setFilteredOptions(filteredOptions)
    focusOnSearchInput()
  }

  const focusOnSearchInput = () => {
    const getInputInterval = setInterval(() => {
      const input = document.getElementById(`search-${labelId}`)
      if (input) {
        input.focus()
        clearInterval(getInputInterval)
      }
    }, 100)
  }

  const handleClick = (option: string) => {
    let value: string | string[]
    if (isMultiple) {
      const valueSet = new Set(selected)
      if (valueSet.has(option)) {
        value = (selected as string[]).filter(
          (selectedItem) => selectedItem !== option
        )
      } else {
        value = [...(selected as string[]), option]
      }
      handleInputChange({
        value: Boolean(value.length) ? value.join(',') : null,
        eventName: name,
      })
    } else {
      value = option
      handleCloseSelect()
      handleInputChange({
        value,
        eventName: name,
      })
    }

    setSelected(value)

    if (showSearch) {
      focusOnSearchInput()
    }
  }

  const getRowHeight = (index: number) =>
    selectOptionsHeight.current[String(index)] + S.SELECT_OPTION_PADDING ||
    S.SELECT_OPTION_HEIGHT

  const getListOptionsHeight = () => {
    const optionsCount = filteredOptions.length

    return optionsCount >= maxOptions
      ? S.SELECT_OPTION_HEIGHT * maxOptions
      : S.SELECT_OPTION_HEIGHT * optionsCount
  }

  const setRowHeight = (index: number, size: number) => {
    if (virtualizedListRef.current) {
      virtualizedListRef.current.resetAfterIndex(0)
      selectOptionsHeight.current = {
        ...selectOptionsHeight.current,
        [index]: size,
      }
    }
  }

  useEffect(() => {
    if (options.length) {
      setFilteredOptions(options)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  useEffect(() => {
    setSelected(arrayValue)
  }, [arrayValue])

  const renderValue = () => {
    if (!isMultiple) return selected
    return (selected as string[]).join(', ')
  }

  return (
    <SelectInput
      fullWidth={Boolean(fullWidth)}
      labelId={`${labelId}-label`}
      label={label}
      id={id}
      minWidth={minWidth}
      multiple={isMultiple}
      open={open}
      disabled={disabled}
      onOpen={handleOpenSelect}
      onClose={handleCloseSelect}
      defaultValue={arrayValue}
      renderValue={renderValue}
      value={selected}
      textAlign={textAlign}
      hideArrow={hideArrow}
      MenuProps={{
        MenuListProps: {
          disablePadding: true,
        },
      }}
    >
      {showSearch && (
        <SearchInput
          fullWidth
          delay={100}
          position="top"
          labelId={labelId}
          handleSearch={handleSearch}
        />
      )}
      <VirtualizedList
        ref={virtualizedListRef}
        width="100%"
        height={getListOptionsHeight()}
        itemCount={filteredOptions.length}
        estimatedItemSize={S.SELECT_OPTION_HEIGHT}
        itemSize={(index) => getRowHeight(index)}
      >
        {({ index, style }) => (
          <SelectOption
            index={index}
            style={style}
            option={filteredOptions[index]}
            isMultiple={isMultiple}
            selected={selected as string[]}
            setRowHeight={setRowHeight}
            handleClick={handleClick}
          />
        )}
      </VirtualizedList>
    </SelectInput>
  )
}

export default Select
