interface GenerateReportDataSetsWithAggregationPropertyParams {
  reportDataSets: ReportDataSet[]
}

const generateReportDataSetsWithAggregationProperty = (
  params: GenerateReportDataSetsWithAggregationPropertyParams
) => {
  const { reportDataSets } = params

  return reportDataSets.map((reportDataSet) => {
    const { properties, aggregationProperty: defaultAggregationProperty } =
      reportDataSet

    if (Boolean(defaultAggregationProperty)) {
      return reportDataSet
    }

    const { aggregation: aggregationProperty = [] }: ReportDataSetProperties =
      properties ? JSON.parse(properties) : []

    return { ...reportDataSet, aggregationProperty }
  })
}

export default generateReportDataSetsWithAggregationProperty
