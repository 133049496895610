import { PROMISE_STATUS } from 'lib/promises'
import { getErrorMessage } from 'lib/errors'

const { FULFILLED, REJECTED } = PROMISE_STATUS

interface SetReportDataSetsTotalParams {
  reportDataSets: ReportDataSet[]
  res: any[]
  limit: number
}

const setReportDataSetsTotal = (params: SetReportDataSetsTotalParams) => {
  const { reportDataSets, res, limit } = params
  if (!Boolean(res.length)) return reportDataSets
  return reportDataSets.map((reportDataSet, i) => {
    const { status: listDataStatus, value, reason: error } = res[i]
    const total: number =
      listDataStatus === FULFILLED ? value.data.listData.total : 1
    const errorMessage =
      listDataStatus === REJECTED
        ? getErrorMessage(error, 'error.reportDataSetTotal')
        : null

    return {
      ...reportDataSet,
      dataSet: {
        ...reportDataSet.dataSet,
        listDataStatus,
        errorMessage,
        total,
        totalQueries: Math.ceil(total / limit),
      },
    } as ReportDataSet
  })
}

export default setReportDataSetsTotal
