import React, { useEffect } from 'react'
import { Typography, Box } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import useUpdateReportDataSets from 'contexts/ReportDataSets/hooks/useUpdateReportDataSets'
import useRunEvents from 'hooks/useRunEvents'
import { Events } from 'hooks/useRunEvents/types'
import { useLayoutState } from 'hooks/components'
import useFormGroups from './hooks/useFormGroups'
import submitReportForm from 'containers/ReportForm/submitReportForm'
import getOptions from 'containers/ReportForm/helpers/getOptions'
import Button from 'components/Button'
import { handleChangeParams } from 'components/Input'
import ReportFormGridItem from 'components/Grid/GridItem'
import ReportFormGrid from 'components/Grid'
import ReportFormInputs from 'components/ReportForm/ReportFormInputs'
import generateCols from 'components/Grid/utils/generateCols'
import { formatMessage } from 'i18n/ShimokuIntl'
import { ReportFormDataset, FieldGroup } from 'components/ReportForm/types'
import {
  FormSendButton,
  FormVariant,
  FormData,
} from 'containers/ReportForm/types'

type ReportFormProps = {
  appId: string
  defaultReportDataSets: ReportDataSet[]
  dataSetId: string
  dataId: string
  defaultFormValues: any
  currentFieldGroups: FieldGroup[]
  sendButton?: FormSendButton
  updateReportDataSets: (reportdataSets: ReportDataSet[]) => void
  columns: number
  variant?: FormVariant
  events: Events
  hasEditPermission?: boolean
}

export const ReportForm = ({
  appId,
  currentFieldGroups,
  dataId,
  defaultFormValues,
  columns: reportFormGridColumns,
  sendButton = {},
  variant: formVariant,
  dataSetId,
  hasEditPermission = true,
  defaultReportDataSets,
  updateReportDataSets,
  events,
}: ReportFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors, isSubmitting },
    setValue,
    unregister,
    reset,
  } = useForm<any>({ defaultValues: defaultFormValues, mode: 'onChange' })
  const { matches: isDesktop } = useLayoutState('md')
  const { fieldGroups, hideFormGroupIds, setHideFormGroupIds } = useFormGroups({
    currentFieldGroups,
  })
  const formValues = useWatch({ control })
  const { enqueueSnackbar } = useSnackbar()
  const { updateReportsDataSetByIdOnMemory } = useUpdateReportDataSets()
  const { onSubmit: onSubmitReportEvent } = useRunEvents({ events, appId })

  const onSubmit = (submitData: FormData) =>
    submitReportForm({
      appId,
      dataId,
      currentFieldGroups,
      defaultFormValues,
      formData: submitData,
      dataSetId,
      updateReportsDataSetByIdOnMemory,
      defaultReportDataSets,
      updateReportDataSets,
      enqueueSnackbar,
      onSubmitReportEvent,
    })
  const {
    label: sendButtonLabel = formatMessage('generic.send'),
    sizeColumns: sendButtonSizeColumns = reportFormGridColumns,
    align: sendButtonAlign = 'start',
  } = sendButton
  const nextFormGroup = {
    id: '',
    label: '',
    sizeColumns: 0,
    align: '',
  }

  const handleFileInputChange = ({ eventName, files }: handleChangeParams) =>
    setValue(eventName, files)

  const handleInputChange = ({ value, eventName }: any) =>
    setValue(eventName, value, { shouldDirty: true })

  const handleNextFormGroup = ({
    nextFormGroupId,
  }: {
    nextFormGroupId: string
  }) => {
    setHideFormGroupIds(
      (prevHideFormGroupIds) =>
        new Set(
          Array.from(prevHideFormGroupIds).filter(
            (hideFormGroupId) => hideFormGroupId !== nextFormGroupId
          )
        )
    )
  }

  useEffect(() => {
    reset(defaultFormValues)
  }, [defaultFormValues, reset]) // eslint-disable-line

  useEffect(() => {
    if (formVariant === 'autoSend' && isDirty && isValid) {
      const autoSend = setTimeout(handleSubmit(onSubmit), 500)

      return () => clearTimeout(autoSend)
    }
  }, [formValues]) // eslint-disable-line

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <ReportFormGrid id="grid-reportForm" columns={reportFormGridColumns}>
        {fieldGroups.map(
          ({
            title,
            nextFormGroup: {
              id: nextFormGroupId,
              label: nextFormGroupLabel,
              sizeColumns: nextFormSizeColumns,
              align: nextFormAlign,
            } = {
              id: undefined,
              label: 'Next',
              sizeColumns: reportFormGridColumns,
              align: 'left',
            },
            fields,
          }: any) => {
            nextFormGroup.id = nextFormGroupId
            nextFormGroup.label = nextFormGroupLabel
            nextFormGroup.sizeColumns = nextFormSizeColumns
            nextFormGroup.align = nextFormAlign

            return (
              <>
                {title && (
                  <ReportFormGridItem
                    columns={reportFormGridColumns}
                    dataTestId={`reportForm-title-${title}`}
                    render={() => (
                      <Typography component="p" color="var(--color-grey-500)">
                        {title}
                      </Typography>
                    )}
                  />
                )}
                {fields.map(
                  ({
                    inputType,
                    mapping,
                    fieldName,
                    required: defaultRequired,
                    options: defaultOptions,
                    dependsOn,
                    showSearch,
                    dragAndDrop,
                    sizeColumns: reportFormGridItemColumns,
                    sizePadding: reportFormGridItemPadding,
                    hide,
                  }: ReportFormDataset) => {
                    const stringMapping = mapping ? mapping.toString() : ''
                    const options = getOptions({
                      inputType,
                      options: defaultOptions,
                      formValues,
                      dependsOn,
                      unregister,
                      setValue,
                      fieldName,
                    })
                    const required =
                      formVariant !== 'autoSend' ? defaultRequired : false

                    return (
                      !Boolean(hide) && (
                        <ReportFormGridItem
                          columns={reportFormGridItemColumns}
                          padding={reportFormGridItemPadding}
                          gridColumns={reportFormGridColumns}
                          dataTestId={`reportForm-input-${fieldName}`}
                          render={() => (
                            <ReportFormInputs
                              hasEditPermission={hasEditPermission}
                              inputType={inputType}
                              required={required}
                              fieldName={fieldName}
                              options={options}
                              stringMapping={stringMapping}
                              control={control}
                              errors={errors}
                              mapping={mapping}
                              handleInputChange={handleInputChange}
                              handleFileInputChange={handleFileInputChange}
                              showSearch={showSearch}
                              dragAndDrop={dragAndDrop}
                            />
                          )}
                        />
                      )
                    )
                  }
                )}
              </>
            )
          }
        )}
        {Boolean(hideFormGroupIds.size) && (
          <ReportFormGridItem
            columns={generateCols({
              columns: nextFormGroup.sizeColumns,
              gridColumns: reportFormGridColumns,
              paddingRight: 0,
              paddingLeft: 0,
            })}
            dataTestId={`reportForm-nextButton-${nextFormGroup.label}`}
            render={() => (
              <Box display="flex" justifyContent={nextFormGroup.align}>
                <Button
                  data-testId={`reportForm-nextButton-${nextFormGroup.label}`}
                  disabled={!isValid || !hasEditPermission}
                  fullWidth={!isDesktop || nextFormGroup.align === 'stretch'}
                  onClick={() =>
                    handleNextFormGroup({
                      nextFormGroupId: nextFormGroup.id,
                    })
                  }
                >
                  {nextFormGroup.label}
                </Button>
              </Box>
            )}
          />
        )}
        {formVariant !== 'autoSend' && (
          <ReportFormGridItem
            columns={generateCols({
              columns: sendButtonSizeColumns,
              gridColumns: reportFormGridColumns,
              paddingRight: 0,
              paddingLeft: 0,
            })}
            dataTestId={`reportForm-sendButton-${sendButtonLabel}`}
            render={() => (
              <Box display="flex" justifyContent={sendButton.align}>
                <Button
                  type="submit"
                  fullWidth={!isDesktop || sendButtonAlign === 'stretch'}
                  disabled={
                    isSubmitting || (!isValid && isDirty) || !hasEditPermission
                  }
                  loading={isSubmitting}
                >
                  {sendButtonLabel}
                </Button>
              </Box>
            )}
          />
        )}
      </ReportFormGrid>
    </Box>
  )
}
