import React from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import Icon from 'components/Icon'
import {
  TablePropertiesColumn,
  TableColumnLink,
  TableChip,
  TableColumnIcon,
} from 'lib/reports/types'
import * as S from './renderTableCell.styled'

interface RenderTableCellParams {
  gridRenderCellParams: GridRenderCellParams
  column: TablePropertiesColumn
}

const renderTableCell = (params: RenderTableCellParams) => {
  const { gridRenderCellParams, column } = params
  const { value, formattedValue, row } = gridRenderCellParams
  const { link, icon, chips } = column
  const cellIcon = row[icon?.name as string]

  if (!Boolean(formattedValue)) {
    return null
  }

  let tableCell = <>{formattedValue}</>

  if (Boolean(icon) && Boolean(cellIcon)) {
    const { color, width = 22, height = 22 } = icon as TableColumnIcon
    tableCell = (
      <S.TableCellIcon>
        <Icon
          color={color}
          width={width}
          height={height}
          name={`Line/${cellIcon}`}
        />
        {tableCell}
      </S.TableCellIcon>
    )
  }

  if (Boolean(link)) {
    const { url, openNewTab = false } = link as TableColumnLink

    tableCell = (
      <S.TableCellLink
        data-testid="table-cell-link"
        href={`${row[url]}`}
        target={openNewTab ? '_blank' : '_self'}
      >
        {tableCell}
      </S.TableCellLink>
    )
  }

  if (Boolean(chips)) {
    const { options, variant = 'filled' } = chips as TableChip
    const { backgroundColor, color } =
      options.find(({ value: optionValue }) => optionValue === value) || {}

    tableCell = (
      <S.TableCellChip
        data-testid="table-cell-chip"
        label={tableCell}
        variant={variant}
        backgroundColor={backgroundColor}
        textColor={color}
      />
    )
  }

  return tableCell
}

export default renderTableCell
