import { styled } from '@mui/material/styles'
import { GridFilterPanel as MUIGridFilterPanel } from '@mui/x-data-grid-pro'
import { panelTextStyles, selectStyles } from '../Table.styled'

export const GridFilterPanel = styled(MUIGridFilterPanel)(() => ({
  padding: '1rem',

  '& .MuiDataGrid-filterForm': {
    padding: 0,
    gap: '0.5rem',

    '& .MuiInputLabel-root': {
      margin: '0 0 0.5rem 0',
    },

    '& .MuiDataGrid-filterFormDeleteIcon': {
      margin: 0,

      '& .MuiButtonBase-root': {
        backgroundColor: 'var(--color-grey-100)',
        borderRadius: 'var(--border-radius-s)',
        padding: '0.375rem',
      },
    },

    '& .MuiInput-root': {
      ...selectStyles,
    },
  },

  '& .MuiDataGrid-panelFooter': {
    margin: '1rem 0 0 0',
    padding: 0,

    '& .MuiButton-root': {
      ...panelTextStyles,
      color: 'var(--color-primary)',
      padding: 0,

      '&:hover': {
        backgroundColor: 'transparent',
      },

      '& .MuiButton-startIcon': {
        backgroundColor: 'var(--color-grey-100)',
        borderRadius: 'var(--border-radius-s)',
        padding: '0.375rem',
      },

      '&:last-child': {
        color: 'var(--complementary-red)',
      },

      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },
  },
}))
