import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react'
import formatReports from './formatReports'

interface ReportsContextValue {
  reports: Report[]
  setReports: Dispatch<SetStateAction<Report[]>>
}

const ReportsContext = createContext<ReportsContextValue | undefined>(undefined)

interface ReportsProviderProps {
  children: ReactNode
  initialReports: Report[]
}

const ReportsProvider = (props: ReportsProviderProps) => {
  const { children, initialReports } = props

  const formattedReports = formatReports({ reports: initialReports })
  const [reports, setReports] = useState<Report[]>(formattedReports)

  const value = { reports, setReports }

  return (
    <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>
  )
}

const useReports = () => {
  const context = useContext(ReportsContext)

  if (context === undefined) {
    throw new Error('useReports must be used within a ReportsProvider')
  }

  return context
}

export { ReportsProvider, useReports }
