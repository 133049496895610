import { getGridDateOperators, GridColDef } from '@mui/x-data-grid-pro'
import renderTableCell from 'containers/Table/utils/renderTableCell'
import { TablePropertiesColumn } from 'lib/reports/types'
import {
  TABLE_COLUMN_TYPES,
  TABLE_COLUMN_FILTER_OPERATORS,
  TableColumnFilterOperatorTypes,
} from 'containers/Table/types'
import { COLUMN_WIDTH } from 'containers/Table/utils/generateTableColumns/getColumn'

const { DATE } = TABLE_COLUMN_TYPES

const {
  DATE_IS,
  DATE_AFTER,
  DATE_ON_OR_AFTER,
  DATE_BEFORE,
  DATE_ON_OR_BEFORE,
} = TABLE_COLUMN_FILTER_OPERATORS
const DATE_OPERATORS = [
  DATE_IS,
  DATE_AFTER,
  DATE_ON_OR_AFTER,
  DATE_BEFORE,
  DATE_ON_OR_BEFORE,
]
export const getDateFilterOperators = () =>
  getGridDateOperators().filter(({ value }) =>
    DATE_OPERATORS.includes(value as TableColumnFilterOperatorTypes)
  )

interface GetDateColumnParams {
  column: TablePropertiesColumn
}

const getDateColumn = (params: GetDateColumnParams): GridColDef => {
  const { column } = params
  const { field, headerName, width = COLUMN_WIDTH } = column

  return {
    field,
    headerName,
    type: DATE,
    width,
    filterOperators: getDateFilterOperators(),
    renderCell: (gridRenderCellParams) =>
      renderTableCell({ gridRenderCellParams, column }),
  }
}

export default getDateColumn
