import { InputType, FormValues, Options } from 'components/ReportForm/types'
import { UseFormSetValue, UseFormUnregister } from 'react-hook-form'

const { select, radio, checkbox } = InputType

interface ResetValuesOnChangeParams {
  inputType: InputType
  formValues: FormValues
  fieldName: string
  options: string[]
  setValue: UseFormSetValue<any>
  defaultOptions: Options
  unregister: UseFormUnregister<any>
}

const resetValuesOnChange = (params: ResetValuesOnChangeParams) => {
  const {
    inputType,
    formValues,
    fieldName,
    options,
    setValue,
    defaultOptions,
    unregister,
  } = params

  if (inputType === select || inputType === radio) {
    const value = formValues[fieldName]

    if (!Boolean(options.length)) {
      value !== '' && setValue(fieldName, '')
    } else {
      !options.some((option) => option === value || value === '') &&
        setValue(fieldName, '')
    }
  }

  if (inputType === checkbox) {
    const setOptions = new Set(options)
    const formKeys = new Set(Object.keys(formValues))
    const checkboxOptionsToUnregister =
      //@ts-ignore
      Object.values(defaultOptions)
        ?.flat()
        .filter(
          (defaultOption) =>
            !setOptions.has(defaultOption) &&
            formKeys.has(`${fieldName}-${defaultOption}`)
        )
        .map((defaultOption) => `${fieldName}-${defaultOption}`)

    checkboxOptionsToUnregister.length &&
      unregister(checkboxOptionsToUnregister)
  }
}

export default resetValuesOnChange
