import { styled } from '@mui/material/styles'
import { GridColumnMenu as MUIGridColumnMenu } from '@mui/x-data-grid-pro'
import { panelTextStyles } from '../Table.styled'

export const GridColumnMenu = styled(MUIGridColumnMenu)(({ theme }) => ({
  '& .MuiTypography-root': {
    ...panelTextStyles,
  },
  '& .MuiMenuItem-root': {
    padding: '0.75rem 1rem',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}0C`,
    },
  },
  '& .MuiDivider-root': {
    display: 'none',
  },
}))
