import generateDataSet from './generateDataSet'
import { ReportDataSetWithMapping, ReportDataSetProperties } from './types'
import { formatMessage } from 'i18n/ShimokuIntl'

export const generateReportDataSetsWithMapping = (
  reportDataSets: ReportDataSet[]
): ReportDataSetWithMapping => {
  return reportDataSets.reduce((reportDataSetsWithMapping, reportDataSet) => {
    const { properties } = reportDataSet
    const { mapping }: ReportDataSetProperties = properties
      ? JSON.parse(properties)
      : {}
    const {
      dataSet: { data },
    } = reportDataSet
    return {
      ...reportDataSetsWithMapping,
      [`#{${reportDataSet.id}}`]: generateDataSet({
        dataItems: data as Data[],
        mapping,
      }),
    }
  }, {})
}

export const generateFormReportDataSetsWithMapping = (
  reportDataSets: ReportDataSet[]
): ReportDataSetWithMapping => {
  return reportDataSets.reduce((reportDataSetsWithMapping, reportDataSet) => {
    const {
      properties,
      dataSet: { data },
    } = reportDataSet
    const { fields, sendButton, variant }: any = properties
      ? JSON.parse(properties)
      : {}
    if (!fields) throw new Error(formatMessage('errors.formWithoutFields'))

    const allData = data?.map((dataItem: Data) => {
      if (!dataItem.customField1)
        throw new Error(formatMessage('errors.formWithoutCustomField1'))
      const parsedCustomField = JSON.parse(dataItem.customField1)
      const fieldsWithReference = {
        dataId: dataItem.id,
        dataSetId: dataItem.dataSetId,
        sendButton,
        variant,
        fields: fields.map((childFields: any) => {
          return {
            ...childFields,
            fields: childFields.fields.map((field: any) => ({
              ...field,
              // @ts-ignore
              mapping: parsedCustomField[field.mapping],
              reference: field.mapping,
            })),
          }
        }),
      }
      return fieldsWithReference
    })

    return {
      ...reportDataSetsWithMapping,
      ...allData,
    }
  }, {})
}
