import { styled, Theme } from '@mui/material/styles'
import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
} from '@mui/material'
import {
  GridToolbarContainer as MUIGridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import { inputStyles } from '../Table.styled'

export const GridToolbarContainer = styled(MUIGridToolbarContainer)(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
    padding: '1rem',

    '& .MuiFormControl-root': {
      padding: 0,
      '& .MuiInput-root': {
        ...inputStyles,
        '& .MuiInput-input': {
          width: '7.5rem',
          fontSize: '1rem',
          padding: '0',
          order: 1,
        },
        '& .MuiButtonBase-root': {
          order: 2,
        },
        '& > svg': {
          order: 3,
        },
      },
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      '& .MuiFormControl-root': {
        order: 1,
        '& .MuiInput-root': {
          '& .MuiInput-input': {
            width: '100%',
          },
        },
      },
    },
  })
)

export const GridToolbarButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',

  [theme.breakpoints.down('sm')]: {
    order: 2,
    justifyContent: 'center',
    gap: '2rem',
  },
}))

const TableToolbarButton: any = (theme: Theme) => ({
  textTransform: 'none',
  color: 'var(--color-black)',
  padding: '0.5rem 0.75rem',
  fontSize: '1rem',
  fontWeight: '400',
  borderRadius: 'var(--border-radius-m)',

  '&:hover': {
    backgroundColor: `${theme.palette.primary.main}0C`,
  },

  [theme.breakpoints.down('sm')]: {
    padding: '0',
    minWidth: '0',
    '& .toolbar-button-text': {
      display: 'none',
    },
    '& .MuiButton-startIcon': {
      margin: 0,
    },
  },
})

export const IconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'var(--color-grey-100)',
  padding: '0.375rem',
  borderRadius: 'var(--border-radius-s)',

  [theme.breakpoints.down('sm')]: {
    padding: '0.75rem',
  },
}))

export const ColumnsButton = styled(GridToolbarColumnsButton)(({ theme }) => ({
  ...TableToolbarButton(theme),
}))

export const FilterButton = styled(GridToolbarFilterButton)(({ theme }) => ({
  ...TableToolbarButton(theme),
}))

export const ButtonExportTableDataToCSV = styled(MUIButton)<MUIButtonProps>(
  ({ theme }) => ({
    ...TableToolbarButton(theme),
  })
)
