import { styled } from '@mui/styles'
import {
  Theme,
  Tabs as DefaultTabs,
  TabsProps as defaultTabsProps,
  Tab as DefaultTab,
  TabProps as defaultTabProps,
} from '@mui/material'
import { TabsVariant } from 'lib/reports/types'
interface TabsProps extends defaultTabsProps {
  theme: Theme
  sticky?: boolean
  tabsVariant: TabsVariant
}

const getTabsStyles = (variant: TabsVariant) => {
  switch (variant) {
    case 'solidRounded':
      return {
        backgroundColor: 'transparent',
      }

    default:
      return {
        backgroundColor: 'var(--color-primary)',
        padding: '0.25rem',
        borderRadius: 'var(--border-radius-m)',
      }
  }
}

export const Tabs = styled(DefaultTabs)(
  ({ theme, sticky, tabsVariant }: TabsProps) => ({
    maxWidth: '100%',
    minHeight: 'auto',
    zIndex: theme.zIndex.appBar - 1,
    '& .MuiTabs-flexContainer': {
      gap: '0.25rem',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    ...getTabsStyles(tabsVariant),
    ...(sticky && {
      position: 'sticky',
      top: '4.25rem',
    }),
  })
)

interface TabProps extends defaultTabProps {
  theme: Theme
  variant: TabsVariant
}

const getTabStyles = (variant: TabsVariant, theme: Theme) => {
  switch (variant) {
    case 'solidRounded':
      return {
        color: 'var(--color-grey-600)',
        padding: '0.5rem 1rem',
        borderRadius: 'var(--border-radius-s)',
        '&:hover': {
          backgroundColor: 'var(--color-primary)',
          color: 'var(--color-white)',
        },
        '&.Mui-selected': {
          backgroundColor: 'var(--color-primary)',
          color: 'var(--color-white)',
        },
      }

    default:
      return {
        color: `${theme.palette.common.white}7F`,
        padding: '0.75rem 2rem',
        borderRadius: 'var(--border-radius-m)',
        '&:hover': {
          backgroundColor: `${theme.palette.common.black}7F`,
          color: 'var(--color-white)',
        },
        '&.Mui-selected': {
          backgroundColor: `${theme.palette.common.black}7F`,
          color: 'var(--color-white)',
        },
      }
  }
}

export const Tab = styled(DefaultTab)(({ theme, variant }: TabProps) => ({
  minHeight: 'auto',
  textTransform: 'none',
  transition: `${theme.transitions.duration.standard}ms`,
  ...getTabStyles(variant, theme),
}))

export const TabPanel = styled('div')(() => ({
  padding: '1.25rem 0 0 0',
}))
