import React from 'react'
import IconArrowUp from 'icons/Line/arrow-up'
import IconArrowDown from 'icons/Line/arrow-down'
import IconFilter from 'icons/Line/filter-a'
import IconRadiusFillLeft from 'icons/Line/radius-fill-left'
import IconRadiusFillRight from 'icons/Line/radius-fill-right'
import IconEyeBlind from 'icons/Line/eye-blind'
import IconColumnsA from 'icons/Line/columns-a'
import IconCercleClose from 'icons/Line/cercle-close'
import IconPlus from 'icons/Line/plus'
import IconDelete from 'icons/Line/delete'
import IconSearch from 'icons/Line/search'
import IconClose from 'icons/Line/close'

const customIcons = {
  //Column
  columnSortedAscendingIcon: () => (
    <IconArrowUp
      width="1.25rem"
      height="1.25rem"
      color="var(--color-grey-700)"
    />
  ),
  columnSortedDescendingIcon: () => (
    <IconArrowDown
      width="1.25rem"
      height="1.25rem"
      color="var(--color-grey-700)"
    />
  ),
  columnFilteredIcon: () => (
    <IconFilter width="1.5rem" height="1.5rem" color="var(--color-grey-700)" />
  ),
  //ColumnMenu
  columnMenuSortAscendingIcon: () => (
    <IconArrowUp width="1.5rem" height="1.5rem" color="var(--color-black)" />
  ),
  columnMenuSortDescendingIcon: () => (
    <IconArrowDown width="1.5rem" height="1.5rem" color="var(--color-black)" />
  ),
  columnMenuPinLeftIcon: () => (
    <IconRadiusFillLeft
      width="1.5rem"
      height="1.5rem"
      color="var(--color-black)"
    />
  ),
  columnMenuPinRightIcon: () => (
    <IconRadiusFillRight
      width="1.5rem"
      height="1.5rem"
      color="var(--color-black)"
    />
  ),
  columnMenuFilterIcon: () => (
    <IconFilter width="1.5rem" height="1.5rem" color="var(--color-black)" />
  ),
  columnMenuHideIcon: () => (
    <IconEyeBlind width="1.5rem" height="1.5rem" color="var(--color-black)" />
  ),
  columnMenuManageColumnsIcon: () => (
    <IconColumnsA width="1.5rem" height="1.5rem" color="var(--color-black)" />
  ),
  //FilterPanel

  filterPanelDeleteIcon: () => (
    <IconCercleClose
      width="1.5rem"
      height="1.5rem"
      color="var(--color-black)"
    />
  ),
  filterPanelAddIcon: () => (
    <IconPlus width="1.5rem" height="1.5rem" color="var(--color-primary)" />
  ),
  filterPanelRemoveAllIcon: () => (
    <IconDelete
      width="1.5rem"
      height="1.5rem"
      color="var(--complementary-red)"
    />
  ),
  //QuickFilter
  quickFilterIcon: () => (
    <IconSearch width="1.5rem" height="1.5rem" color="var(--color-black)" />
  ),
  quickFilterClearIcon: () => (
    <IconClose width="1.5rem" height="1.5rem" color="var(--color-black)" />
  ),
}

export default customIcons
